.cards_products {
  display: flex;
  justify-content: center;
  margin-top: 7.5%;
  flex-wrap: wrap;
  gap: 8rem;
  padding-bottom: 3rem;
}

.card {
  background-color: #2b7d6c;
  width: 25rem;
  object-fit: contain;
  border-radius: 3rem;
  max-width: 95%;
}

.card-body {
  display: flex;
  flex-direction: column;
  color: #fafafa;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  margin-bottom: 5%;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 200%;
}

.botao2 {
  margin-top: 2.5%;
  margin-bottom: 10%;
  padding: 4% 10%;
  border-radius: 2rem;
  font-size: 1.5rem;
  font-weight: bolder;
  width: fit-content;
  max-width: 90%;
}

.botao_duplo{
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: flex-end;
 }

 .botao_duplo2{
  display: flex;
  align-items: center;
  justify-content: space-between;
}